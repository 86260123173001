<template>
    <!--专利-->
    <div>
        <el-dialog :title="operation ? '添加' : (optType?'编辑':'查看')"
                   :visible.sync="SakaryVisible"
                   v-model="SakaryVisible"
                   :close-on-click-modal="false">
            <el-form ref="SakaryForm"
                     :model="SakaryForm"
                     class="demo-form-inline"
                     label-width="100px">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="申请人:" prop="Applicant" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.Applicant" :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专利名称:" prop="PatentName" :rules="[ { required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.PatentName" :disabled ="!optType"> </el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专利号:" prop="PatentNumber" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.PatentNumber" :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="证书编号:" prop="CertificateNumber" :rules="[ { required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.CertificateNumber" :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="授权公告号:" prop="AnnouncementNumber" :rules="[  { required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-input type="text" v-model="SakaryForm.AnnouncementNumber" :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="专利类型:" prop="Type" :rules="[ { required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-select v-model="SakaryForm.Type" placeholder="请选择" style="width: 100%"  :disabled ="!optType">
                                <el-option label="实用新型" value="实用新型"></el-option>
                                <el-option label="发明 " value="发明"></el-option>
                                <el-option label="外观设计 " value="外观设计"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="专利法律状态:" prop="State" :rules="[ { required: true, message: '不能为空', trigger: 'blur' },]">
                            <el-select v-model="SakaryForm.State" placeholder="请选择" style="width: 100%"  :disabled ="!optType">
                                <el-option label="有效" value="有效"></el-option>
                                <el-option label="失效 " value="失效"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col :span="12">
                        <el-form-item label="申请日期:" prop="ApplicationDate" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-date-picker type="date" v-model="SakaryForm.ApplicationDate" value-format="yyyy-MM-dd" placeholder="选择日期" @change="ApplicationDateChange($event)"  :disabled ="!optType"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="授权日期:" prop="AuthorizationDate" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-date-picker type="date" v-model="SakaryForm.AuthorizationDate" value-format="yyyy-MM-dd" placeholder="选择日期" @change="AuthorizationDateChange($event)" :disabled ="!optType"></el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="有效日期:" prop="EffectiveDate" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-date-picker type="date" v-model="SakaryForm.EffectiveDate" value-format="yyyy-MM-dd" placeholder="选择日期"  :disabled ="!optType"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="获取方式:" prop="AcquireWay" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                            <el-select v-model="SakaryForm.AcquireWay" placeholder="请选择" style="width: 100%"  :disabled ="!optType">
                                <el-option label="自主研发" value="自主研发"></el-option>
                                <el-option label="转让" value="转让"></el-option>
                                <el-option label="合作研发 " value="合作研发"></el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="申请年度:" prop="ApplicationYear" :rules="[ { required: true, message: '不能为空', trigger: 'blur' }, ]">
                                <el-input type="text" v-model="SakaryForm.ApplicationYear" disabled></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="授权年度:" prop="AnnouncementYear" :rules="[{ required: true, message: '不能为空', trigger: 'blur' },]">
                                <el-input type="text" v-model="SakaryForm.AnnouncementYear" disabled></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="24">
                            <el-form-item label="应用产品">
                                <el-input type="textarea" v-model="SakaryForm.Product"  :disabled ="!optType"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-col :span="24">
                        <el-form-item label="简要说明">
                            <el-input type="textarea" v-model="SakaryForm.Explain"  :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="备注">
                            <el-input type="textarea" v-model="SakaryForm.Remark"  :disabled ="!optType"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24" v-if="optType">
                        <el-form-item label="发明人" label-width="120px">
                            <el-button @click="chooseUser()">选择发明人</el-button>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="SakaryForm.Persons" style="width: 100%" class="bg-color">
                        <el-table-column prop="UserName" label="姓名"></el-table-column>
                        <el-table-column label="操作" width="150" v-if="optType">
                            <template scope="scope">
                                <el-button size="small" v-on:click="PersonsDelete(scope.row)" type="text">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <upload-files :files="SakaryForm.Files" :key="key" action="/cyl/ftp/ftp/upload" :limit="20" @fun="dealFiles"
                              :IsDisabled="optType" :IsDel="optType"></upload-files>
                <el-row v-if="optType">
                    <el-col :span="24" style="text-align: center">
                        <el-button type="primary" v-on:click="onSubmit('SakaryForm')">提交</el-button>
                    </el-col>
                </el-row>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    import UserChoose from "../../components/UserChoose";
    import UserChoosejob from "../../components/UserChoosejob";
    import UploadFiles from "../../components/UploadFiles";
    import {
        PatentAddInfo,
        PatentUpdateInfo,
        PatentGetInfoList,
    } from "../../api/hr";
    //import { getButtonList } from "../../promissionRouter";
    //import Toolbar from "../../components/newToolbar";
    export default {
        components: { /*Toolbar,*/ UserChoose, UserChoosejob, UploadFiles },
        data() {
            return {
                optType: false,   // 编辑/查看
                operation: false, // true:新增, false:编辑
                operation1: true, // 是否显示上传附件

                key: 0,
                SakaryVisible: false,
                
                addUserVisible: false, // 人员选择dlg标识
                buttonList: [],
                SakaryForm: {
                    PatentName: null,
                    PatentNumber: null,
                    Type: null,
                    State: null,
                    AnnouncementNumber: null,
                    CertificateNumber: null,
                    ApplicationDate: null,
                    AuthorizationDate: null,
                    EffectiveDate: null,
                    Applicant: null,
                    AcquireWay: null,
                    Explain: null,
                    Product: null,
                    ApplicationYear: null,
                    AnnouncementYear: null,
                    Remark: null,
                    Persons: [],
                    Files: [],
                },
            };
        },
        methods: {
            // 选中一行显示
            onShowDlg(pid) {
                PatentGetInfoList({ id: pid }).then((res) => {
                    this.SakaryForm = res.data.response;
                    this.key += 1;
                    this.SakaryVisible = true;
                });

                this.operation = false;
                this.operation1 = false;
            },
            // 多行 人员删除
            PersonsDelete(row) { 
                this.$confirm("确认要删除吗？", "提示", {
                    type: "warning",
                }).then((_) => {
                    this.SakaryForm.Persons.splice(this.SakaryForm.Persons.indexOf(row), 1);
                });
            },
            chooseUser() {
                this.addUserVisible = true;
            },

            ApplicationDateChange(o) {
                this.SakaryForm.ApplicationYear = o.slice(0, 4);
            },
            AuthorizationDateChange(o) {
                this.SakaryForm.AnnouncementYear = o.slice(0, 4);
            },
            
            onSubmit(formName) {
                this.$confirm("确定要提交吗？", "提示", {}).then(() => {
                    this.$refs[formName].validate((valid) => {
                        if (valid) {
                            let para = Object.assign({}, this.SakaryForm);
                            if (this.operation) {
                                //新增
                                PatentAddInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "添加成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.SakaryVisible = false;
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            } else {
                                //修改
                                PatentUpdateInfo(para).then((res) => {
                                    if (res.data.success) {
                                        this.$message({
                                            message: "修改成功",
                                            type: "success",
                                            duration: 5000,
                                        });
                                        this.SakaryVisible = false;
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: "error",
                                            duration: 5000,
                                        });
                                    }
                                });
                            }
                        } else {
                            this.$message({
                                message: "请检查必填项",
                                type: "error",
                                duration: 5000,
                            });
                            return false;
                        }
                    });
                });
            },
            dealFiles(data) {
                console.log(data.backData);
                this.SakaryForm.Files = data.backData;
            },
        },
        mounted() {
            let routers = window.localStorage.router
                ? JSON.parse(window.localStorage.router)
                : [];
            //let buttons = window.localStorage.buttList
            //    ? JSON.parse(window.localStorage.buttList)
            //    : [];
            //this.buttonList = getButtonList(this.$route.path, routers, buttons);
        },
    };
</script>

<style scoped>
    .el-date-editor.el-input,
    .el-date-editor.el-input__inner {
        width: 100%;
    }
</style>